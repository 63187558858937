.button {
  border-radius: 10px;
  background-color: #0b2e4e;

  padding: 5px 30px !important;
  color: #fff;
  border-radius: 5px !important;
  &:hover {
    color: #fff;
    background-color: #010c17;
    border-color: #010c17;
  }
}
.bg_violet {
  background-color: $color_violet !important;
}
.text_violet {
  color: $color_violet !important;
}

.text_green {
  color: $text-green !important;
}

.pointer_event {
  cursor: pointer;
}
.tableCheckBox {
  width: 0.7rem !important;
  height: 1rem !important;
  min-height: 1rem !important;
}

.nav-tabs-custom .nav-item .nav-link.active {
  color: $color_violet !important;
}

.nav-tabs-custom .nav-item .nav-link::after {
  background-color: $color_violet !important;
}
