p {
  font-size: 13px;
  margin-bottom: 0;
}
.btn-check:focus + .btn-success,
.btn-success:focus {
  color: #fff;
  background-color: #2ba3c2;
  border-color: #146c4300;
  box-shadow: 0 0 0 0.25rem rgb(60 153 110 / 0%);
}
.btn-check:active + .btn-success,
.btn-check:checked + .btn-success,
.btn-success.active,
.btn-success:active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #2ba3c2;
  border-color: #2ba3c2;
}
.text-green {
  color: #2ba3c2 !important;
}

.btn-green {
  background-color: #2ba3c2 !important;
  color: #fff !important;
  border: none;
}
.btn-check:active + .btn-success:focus,
.btn-check:checked + .btn-success:focus,
.btn-success.active:focus,
.btn-success:active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgb(60 153 110 / 0%);
}
.dropdown-item.active,
.dropdown-item:active {
  color: unset;
  text-decoration: none;
  background-color: unset;
}
.btn-check:active + .btn-secondary:focus,
.btn-check:checked + .btn-secondary:focus,
.btn-secondary.active:focus,
.btn-secondary:active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem #828a9100 !important;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  box-shadow: 0 0 0 0.25rem #828a9100;
}
// table > {
//   thead {
//     tr {
//       th {
//         padding: 0.95rem 0.6rem !important;
//       }
//     }
//   }
//   tbody {
//     border-top: 1px solid #eee !important;
//     tr {
//       td {
//         padding: 0.5rem 0.6rem !important;
//       }
//     }
//   }
// }
button {
  font-family: "Sora";
  > {
    svg {
      position: relative;
      right: 7px;
    }
  }
  &.dropdown-toggle {
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
    display: inline-block;
    text-transform: none;

    &::after {
      display: inline-block;
      margin-left: 0em;
      vertical-align: 0em;

      border-top: 0em solid;
      border-right: 0em solid transparent;
      border-bottom: 0;
      border-left: 0em solid transparent;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f107";
    }
  }
}
h1 {
  margin-bottom: 0;
}
h2 {
  margin-bottom: 0;
}
h3 {
  margin-bottom: 0;
}
h4 {
  margin-bottom: 0;
}
h5 {
  margin-bottom: 0;
}
h6 {
  margin-bottom: 0;
}
p {
  margin-bottom: 0;
}
ul {
  padding-left: 0;
}
::placeholder {
  font-size: 13px;
}
.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 0%) !important;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 0%) !important;
}

body {
  label {
    margin-bottom: 0;
  }
  input {
    width: 100%;
    height: 100%;
    font-size: 15px;
    padding-left: 7px;
  }
  .table-responsive {
    overflow-y: unset !important;
  }
  input[type="checkbox"] {
    width: 20px !important;
    height: 20px !important;
  }
  hr:not([size]) {
    height: 2px;
  }
  input[type="search"]::-webkit-search-decoration:hover,
  input[type="search"]::-webkit-search-cancel-button:hover,
  input[type="search"]::-webkit-search-cancel-button {
    cursor: pointer;
  }
}
