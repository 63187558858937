body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f8fb;
  font-family: "Sora", sans-serif;
  font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.react-responsive-modal-root {
  z-index: 9999;
}
.page-content {
  padding: calc(70px + 24px) calc(24px / 2) 60px calc(60px / 2);
  transition: 0.3s;
}
.simplebar-wrapper {
  background: #fff;
  position: relative;
  .media {
    gap: 10px;
  }
}
.invoice-create-link {
  border: 1px solid #159b80;
  padding: 3px 8px;
  color: #159b80;
}

.invoice-inner-area-wrap {
  background: #fff;
  padding: 70px 40px;
  border-radius: 10px;
  .invoice-inner-header {
    display: flex;
    justify-content: space-between;
    span {
      color: #7e868c;
      display: block;
      font-size: 10px;
      margin-bottom: 3px;
    }
    > {
      * {
        width: 50%;
        h3 {
          font-size: 22px;
        }
      }
      .invoice-inner-header-right {
        h3 {
          color: #159b80;
        }
        span {
          color: #7e868c;
          display: block;
          font-size: 10px;
          margin-bottom: 3px;
        }
      }
    }
    tbody.invoice-header-table-data {
      border-top: none !important;
      tr td {
        padding: 3px 0 !important;
        font-size: 10px;
        &:first-child {
          color: #7e868c;
        }
        &:last-child {
          color: #000;
        }
      }
    }
  }
  .invoice-inner-footer {
    display: flex;
    justify-content: space-between;
    > * {
      &:last-child {
        width: 40%;
      }
    }
    .invoice-inner-header-right {
      margin-top: 25px;
      > * {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3px;
        span {
          font-size: 12px;
        }
        h4 {
          color: #000;
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
  }
}
.invoice-inner-header-left {
  p {
    font-size: 10px;
    margin-bottom: 3px;
  }
  & > * > {
    h6 {
      font-size: 14px;
    }
    p {
      font-size: 10px;
      margin-bottom: 3px;
    }
  }
}
.invoice-inner-header-right > * > {
  h6 {
    font-size: 14px;
  }
  p {
    font-size: 10px;
    margin-bottom: 3px;
  }
}

table.invoice-detailes-info.table.table-borderless {
  width: 70%;
}

.invoice-inner-body {
  margin: 30px 0;
  tbody tr {
    border-bottom: 1px solid #eee;
    td {
      padding: 13px 10px !important;
      font-size: 12px;
    }
  }
}

.invoice-inner-footer-bottom-wrap {
  margin-top: 40px;
  > p {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    font-size: 10px;
  }
  .footer-bottom-site-info {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 9px;
    }
  }
}
.main-content {
  margin-bottom: 40px;
  // .content-layout-header-left h4 {
  //   font-weight: 700;
  // }
  .content-file-upload-top .content-file-search-area {
    padding: 5px 10px;
    padding-left: 12px;
  }
  // .content-file-upload-top .content-file-upload-dropdown-area button {
  //   font-weight: 600;
  // }
  .table-inner-dropdown-right-btn > span {
    width: 7px;
    height: 7px;
    border: 1px solid #000000;
  }
  // .content-file-upload-top > * > button {
  //   font-weight: 600;
  // }
  .user_table_card.-table-padding-remove.border-0.card > .card-body {
    padding: 0;
  }
  .btn-primary {
    color: #fff;
    background-color: #2665ae;
    border-color: #2665ae;
    &:hover {
      background-color: transparent;
      border-color: #2665ae;
      color: #2665ae;
    }
  }
  .btn-danger {
    color: #f46a6a;
    background-color: #f46a6a00;
    border-color: #f46a6a;
    &:hover {
      background-color: #f46a6a;
      border-color: #f46a6a;
      color: #fff;
    }
  }
  // .new-playlist-single-btn {
  //   font-weight: 600;
  // }
  .playlist-data-container-main-wrap > * > * > .bg-white > * {
    padding: 10px 20px !important;
  }
  .campaign-icon-inner-wrap {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  // .bill-generate-submit-area {
  //   display: flex;
  //   align-items: center;
  //   justify-content: end;
  //   width: 100%;
  //   padding-bottom: 50px;
  //   * {
  //     font-weight: 600;
  //     padding: 10px 25px !important;
  //   }
  // }
  .generate-bill-main-area-wrap tbody > tr:last-child {
    border-color: transparent;
  }
  td {
    .dropdown-menu.show {
      // top: 50px !important;
      box-shadow: 0px 0px 10px 0px #eee !important;
      z-index: 999999 !important;
      display: block;
    }
  }
}
.content-file-upload-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  gap: 20px;
  padding-bottom: 20px;
  .content-file-search-area {
    border: 1px solid #ddd;
    border-radius: 7px;
    padding: 5px 10px;
    height: 42px;
    width: 318px;
    display: flex;
    align-items: center;
    background: #fff;
    input {
      border: none;
      outline: none;
      background: none;
    }
  }
  .content-file-upload-dropdown-area {
    button {
      background: #159b80;
      border: 1px solid #ddd;
      border-radius: 7px;
      padding: 5px 30px;
      height: 50px;
      &.dropdown-toggle::after {
        display: none;
      }
    }
    .dropdown-menu.show {
      top: 60px !important;
      box-shadow: 0px 10px 30px rgb(185 194 192 / 25%);
      border-radius: 10px;
      padding: 15px 30px;
      border: none;
      right: 0 !important;
      left: unset !important;
      .dropdown-item {
        > a {
          color: #a8a8a8;
          text-decoration: none;
          display: flex;
          gap: 15px;
        }
        display: flex;
        gap: 15px;
        padding: 10px 0px;
        &:hover {
          background-color: #e9ecef00;
        }
        * {
          color: #a8a8a8;
          stroke: #a8a8a8;
        }
      }
    }
  }
}

.snippet {
  position: relative;
  padding: 0 5%;
  border-radius: 0.25rem;
}

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem 0;
  margin: 0 -5%;
  overflow: hidden;
}

// .filter-contrast {
//   filter: contrast(5);
//   background-color: white;
// }

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #159b80;
  color: #159b80;
  box-shadow: 9999px 0 0 -5px #159b80;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #159b80;
  color: #159b80;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #159b80;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #159b80;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

.active {
  color: #2665ae;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #159b80;
  }
  30% {
    box-shadow: 9984px 0 0 2px #159b80;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #159b80;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #159b80;
  }
  30% {
    box-shadow: 9999px 0 0 2px #159b80;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #159b80;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #159b80;
  }
  30% {
    box-shadow: 10014px 0 0 2px #159b80;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #159b80;
  }
}

.text-orange {
  color: #ff9000 !important;
}

.attendance-color {
  .text-warning {
    color: #ffcc00 !important;
  }

  .text-danger {
    color: #ff0000 !important;
  }
}

.service_formate {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 25px;
  margin-bottom: 20px;

  button {
    text-transform: capitalize;
    padding: 6px 14px;
    border-radius: 4px;
    border: none;
    color: #222;
    background-color: #e2e1e1;

    &:disabled {
      color: #999;
    }

    &.active {
      color: #fff;
      background-color: #2ba3c2;
    }
  }
}

.cv_volt {
  .applicant-p-single-info {
    .text {
      color: #292d32 !important;
    }
    .icon {
      svg path {
        stroke: #292d32 !important;
      }
    }
  }
}

.custom_pdf {
  height: 80vh;
  width: 100%;
  overflow-y: scroll;
  #custom_pdf_viewer {
    width: 100%;
    canvas {
      width: 100% !important;
      height: 100% !important;
      // object-fit: contain;
      // scale: 1.5 !important;
    }
  }
}

.dark_btn {
  background: #0c4854 !important;
  color: #fff !important;
  padding: 2px 7px !important;
  border-radius: 4px;
}

.remove-select {
  user-select: none !important;
}

.small-btn {
  background-color: #e87c86 !important;
  padding: 3px 6px !important;
  &:disabled {
    color: #f2f2f2;
    background-color: #f15363 !important;
  }
}

.list_title {
  font-size: 16px !important;
  text-align: center !important;
  color: #222 !important;
  margin-bottom: 8px !important;
  display: none;
}

// Used invoice page
.print_assessment {
  @media print {
    padding: 20px;
    width: 100% !important;
    height: 100% !important;
    background-color: #ffffff !important;

    .user_table_card {
      box-shadow: none !important;
    }

    .user_table_card {
      border-left-color: transparent !important;
    }

    .list_title {
      display: block !important;
    }

    .paginationContainer,
    .content-file-upload-top {
      display: none;
    }

    thead,
    tbody {
      td:first-child,
      td:last-child,
      th:first-child,
      th:last-child {
        display: none;
      }
    }

    thead,
    tbody {
      th:nth-child(2),
      td:nth-child(2) {
        padding-left: 5px !important;
      }
    }

    .title-text {
      display: block !important;
    }

    .table-responsive-custom-table {
      overflow: auto !important;
      height: 100%;
    }

    .salary_sheet {
      padding: 10px !important;
      box-shadow: none !important;
    }
    .content-card-area {
      display: none !important;
    }

    table td,
    table th {
      font-size: 7px !important;
      padding: 4px 0 !important;
      margin: 0 !important;

      .font-size-11 {
        padding: 0 !important;
        margin: 0 !important;
        background-color: transparent !important;
        color: #495057 !important;
        font-size: 7px !important;
      }
    }
  }
}

.select__control {
  padding: 3px !important;
}
